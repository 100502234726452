
import React from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Login, Home, NotFound } from './pages';


function useAuth(element) {
  const hasLogin = false;
  const location = useLocation();
  const ele = hasLogin ? <element />:
    <Navigate to="/login" replace state={{ from: location }} />
  return ele;
}

function App(props) {
  return (
    <>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="home" element={useAuth(Home)} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
