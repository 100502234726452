
import { Link, useLocation, useNavigate } from "react-router-dom";

function Home(props) {
	return (<>
		<div className="w-full flex h-20 bg-indigo-100 text-gray-800">
			<div className="container md:mx-auto flex justify-between px-10">
				<h1 className="text-center self-center text-2xl">React</h1>
				<div className="justify-self-stretch h-full flex items-center gap-4 place-self-end">
					<Link to="/about">About</Link>
					<Link to="/login" className="">Login</Link>
				</div>
			</div>
		</div>
		<div>Home page</div>
	</>);
}

export default Home;
