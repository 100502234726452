
function Login(props) {
	return (
		<div className="container">
			<div className="container mx-auto">
				You need login
			</div>
		</div>
	);
}

export default Login;

